body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  font-family: Organic, 'Helvetica Neue', sans-serif, sans-serif;
  font-size: 1.5rem;
}

.about-me {
  padding: 8em 0;
  background: #656d4a;
}

.vertical-timeline-element-date {
  font-size: 1.5rem!important;
}

@media (max-width: 1169px) {
  .vertical-timeline-element--react {
    left: 37% !important;
    top: 37% !important;
    width: 85% !important;
    height: 85% !important;
  }
  .vertical-timeline-element--ruby {
    fill: white !important;
    width: 65% !important;
    height: 65% !important;
    left: 44% !important;
    top: 44% !important;
  }

  .vertical-timeline-element--file {
    font-size: 2rem !important;
    padding: 18% 50% 0 0;
    color: white;
  }

  .vertical-timeline-element--playwright {
    left: 32% !important;
    top: 30% !important;
    width: 95% !important;
    height: 95% !important;
  }
}

@media (min-width: 1170px) {
  .vertical-timeline-element--react {
    width: 85% !important;
    height: 85% !important;
    left: 29% !important;
    top: 29% !important;
  }

  .vertical-timeline-element--ruby {
    fill: white !important;
    width: 70% !important;
    height: 70% !important;
    left: 33% !important;
    top: 33% !important;
  }

  .vertical-timeline-element--file {
    font-size: 2.5rem !important;
    padding: 25% 0 0 10%;
    color: white;
  }

  .vertical-timeline-element--playwright {
    left: 25% !important;
    top: 25% !important;
    width: 95% !important;
    height: 95% !important;
  }

  .vertical-timeline-element--wdio {
    left: 40% !important;
    top: 40% !important;
    width: 37px !important;
    height: auto !important;
  }
.vertical-timeline-element--nextjs {
  width: 4rem !important;
  height: 4rem !important;
  left: 24% !important;
  top: 24% !important;
}
}


.vertical-timeline-element--react g {
  fill: white;
}
